import * as React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import IntroImg from '../images/ian-mcabee.png';
import { motion } from 'framer-motion';
import * as aboutStyles from '../styles/about.module.scss';

const About = ({ transitionStatus, entry, data }) => {
  return (
    <Layout>
      <Seo title="About Ian McAbee" />
      <div className="container-fluid">
        <section className={`container ${aboutStyles.intro}`}>
          <div className={`basis-2/3 ${aboutStyles.left_col}`}>
            <h1>{data.wpPage.acfIntro.introHeading}</h1>
            <p
              className="text-xl mt-6"
              dangerouslySetInnerHTML={{
                __html: data.wpPage.acfIntro.introDescription,
              }}></p>
          </div>
          <img src={IntroImg} alt="photo of ian mcabee" />
        </section>
        <section className={`${aboutStyles.bottom}`}>
          <div className="container">
            <div className={aboutStyles.two_col}>
              <div className={`basis-2/3 ${aboutStyles.experience}`}>
                <h2 className="mb-5">Education</h2>
                <h3 className="mb-2">
                  {data.wpPage.acfAbout.education.school}
                </h3>
                <span>{data.wpPage.acfAbout.education.certificate}</span>
                <h2 className="mt-10">Professional Experience</h2>
                {data.wpPage.acfAbout.experience.description}
                <ul>
                  {data.wpPage.acfAbout.experience.workExperiences.map(
                    (experienceItem) => (
                      <li className="mb-10" key={experienceItem.organization}>
                        <span className={aboutStyles.date}>
                          {experienceItem.timeframe}
                        </span>
                        <span className="font-bold">
                          {experienceItem.organization}
                        </span>{' '}
                        -{' '}
                        <span className={aboutStyles.title}>
                          {experienceItem.title}
                        </span>
                        <br />
                        <p className={aboutStyles.description}>
                          {experienceItem.description}
                        </p>
                      </li>
                    )
                  )}
                </ul>
                <a
                  className="bg-teal-900 hover:bg-teal-700 text-white py-4 px-6 rounded"
                  target="_blank"
                  href={data.wpPage.acfAbout.resume}>
                  Download resume
                </a>
              </div>
              <div className={`basis-1/2 ${aboutStyles.skills}`}>
                <h2>Design &amp; Research</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wpPage.acfAbout.designResearch,
                  }}
                  className="list-none"
                />
                <h2>Development</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wpPage.acfAbout.development,
                  }}
                  className="list-none"
                />
                {/* <h2>Tooling</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wpPage.acfAbout.tooling,
                  }}
                  className="list-none"
                /> */}
                <h2>Certifications</h2>
                <ul className="list-inline">
                  {data.wpPage.acfAbout.certifications.map(
                    (certificationItem) => (
                      <li className="m-0" key={certificationItem.certification}>
                        {certificationItem.certification}
                        <br />
                        Issued {certificationItem.issuedDate}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default About;

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "about" }) {
      acfIntro {
        introHeading
        introDescription
      }
      acfAbout {
        education {
          school
          certificate
        }
        experience {
          description
          workExperiences {
            timeframe
            organization
            title
            description
          }
        }
        resume
        designResearch
        development
        tooling
        certifications {
          certification
          issuedDate
        }
      }
    }
  }
`;
